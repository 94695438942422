@font-face {
  font-family: "gaegu";
  src: url("./fonts/gaegu.ttf");

  }

@font-face {
  font-family: "topsecret";
  src: url("./fonts/Top_Secret.ttf");

  }

.App {
  text-align: center;
}
.App-background {
  text-align: center;
  z-index: 1;
}
.App-mid {
  text-align: center;
  z-index: 2;
}
.App-content {
  z-index: 2;
  color: white;
  font-family: "gaegu";
  font-size: calc(10px + 3vmin);
  display: flex;
  justify-content: space-between;
  margin-inline: auto;
  width: min(60%, 800px); /* 100% of the viewport width */
  flex-direction: column;
  position: relative; /* or remove it */

  margin-top: 0vh;
  padding-top: 25vh;
  padding-left: 20vw;

  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: 'gaegu';
  text-shadow: 2px 2px 2px #111;
}


.freemode {
  position: fixed;
  z-index: 10;
  color: red;
  font-family: 'topsecret';
  top: 10vh;
  left: 5vh;
  max-width: 5vh;
  transform: rotate(-15deg);
  font-size: calc(2px + 2vmin);
  padding-left: 2px;

}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #66ccff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "gaegu";
  z-index: 3;
  overflow-y: auto;
}

.sky {
  background-color: #66ccff;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1;
}

.overlay {
  position: fixed;
  color: white;
  font-family: "gaegu";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000; /* make sure it's above everything else */
  padding: 20px; /* added padding around the overlay */
  overflow-y: auto;
}

.overlay p {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  width: calc(100% - 40px); /* reduced width to account for padding */
  font-size: calc(10px + 4vmin);
  padding: 0 20px; /* added padding on the sides */
  margin: 10px 0; /* added vertical margins */
}

.overlay-content h1 {
  color: white;
  display: flex;
  justify-content: center;
  margin-inline: auto;
  width: calc(100% - 40px); /* reduced width to account for padding */
  font-size: calc(10px + 5vmin);
  padding: 0 20px; /* added padding on the sides */
  margin: 10px 0; /* added vertical margins */
  text-decoration: underline;
}

.overlay-content li {
  color: white;
  display: flex;
  justify-content: center;
  margin-inline: auto;
  width: calc(100% - 40px); /* reduced width to account for padding */
  font-size: calc(10px + 4vmin);
  padding: 0 20px; /* added padding on the sides */
  margin: 10px 0; /* added vertical margins */
  list-style-type: none;
}

.overlay-content {
  margin-top: 100px; /* adjust this value according to your nav bar's height */
}

/* Media query for screens smaller than 800px */
@media (max-width: 800px) {
  .overlay p {
    font-size: calc(8px + 3vmin); /* reduce font size */
    padding: 0 10px; /* reduce padding on the sides */
  }
}

h1 {
  z-index: 3;
  color: #111;
}

audio {
  z-index: 2;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Nav {
  position: fixed;
  top: 0;
  z-index: 15;
  width: 100%;
}

.Nav ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.Nav li {
  font-family: "gaegu";
  font-size: calc(10px + 2vmin);
  display: block;
}

.Nav li a {
  color: white;
  text-align: center;
  padding: 10px 30px;
  text-decoration: none;
}

.Nav li a:hover:not(.active) {
  background-color: #111;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-link {
  position: fixed;  /* Absolute positioning */
  left: 10px;          /* Distance from the left edge */
  top: 10px;    /* Fine-tune vertical centering */
  z-index: 20;
}

.custom-input {
  font-family: "gaegu";
  font-size: 18px;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
  background-color: #f9f9f9;
  font-size: calc(10px + 4vmin);
}

/* To remove blue outline when input is focused */
.custom-input:focus {
  outline: none;
  border: 1px solid #aaa;
}

.storyQuestion {
  font-size: calc(10px + 4vmin);
}
.menu-button {
  width: 100%;
  font-family: "gaegu";
  font-size: calc(10px + 3vmin);
  transition: all 5s;
  cursor: pointer;
  margin: 0.5px;
  background-color: #111;
  height: 10vh;
  z-index: 4;
}

.menu-button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  background-color: #111;
}

.menu-button span:after {
  content: '\2193';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.menu-button:hover span {
  padding-right: 30px;
}

.menu-button:hover span:after {
  opacity: 1;
  right: 0;
}

.nav-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 80vh;
}

.logo-link img {
  height: 3.5rem;
}


.version-text {
  font-size: 0.8em;
  position: relative;
}

.log-buttons {
  margin-left: auto;
}

.log-buttons button {
  font-family: "gaegu";
  font-size: 1.2em; /* Increase the font size */
  padding: 10px 20px; /* Adjust the padding to increase the button size */
}

span {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #333;
  color: white;
  padding: 5px;
}

.sun {
  width: 10vh;
  height: 10vh;
  position: fixed;
  top: 20vh;
  left: 15vh;
  transform: rotate(45deg);
  animation: sun 60s infinite linear;
  z-index: 1;
}

.text {
  font-size: calc(10px + 3vmin);
}
@keyframes sun {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.cloud {
  position: fixed;
  top: 19vh;
  left: 0;
  width: 40%; /* Adjust the width as needed */
  max-height: 40%;
  z-index: 1;
}

.sea-background {
  position: fixed;
  bottom: -15vh;
  left: 0;
  z-index: 1;
  width: 100%;
  height: auto;
}



.svg-background {
  position: fixed;
  bottom: -15vh;
  left: 0;
  z-index: 1;
  min-width: 100%;
  /* max-height: 15%; */
  /* Add any other styles for the SVG */
}

.grass {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 33vh;
  z-index: 1;
  background-color: '#C0CF28';
}

.rainforest {
  position: fixed; /* or absolute, depending on your layout */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-position: center;
  background-size: contain;
  background-position: center;
}

.tree {
  position: fixed;
  bottom: 25vh;
  right: 10vw;
  z-index: 2;
}

.meadow-left {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  max-height: 40vh; /* Maximum height is 40% of the viewport height */
  width: auto; /* This ensures the aspect ratio is maintained */
}

.meadow-right {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 3;
  max-height: 40vh; /* Maximum height is 40% of the viewport height */
  width: auto; /* This ensures the aspect ratio is maintained */
}

.svg-fyn {
  position: fixed;
  bottom: 15vh;
  left: 5vw;
  z-index: 10;
  height: 25vh;
  filter: drop-shadow(20px 40px 40px #4a4949);
  cursor: pointer; /* Add this line */
}

.speech-bubble {
  position: fixed;
  background: #00aabb;
  border-radius: .4em;
  color: white;
  padding: 20px;
  bottom: 40vh; /* Adjust this value so it appears above Fyn */
  left: 5vw; /* Adjust this value so it appears to the right of Fyn */
  z-index: 11; /* Make sure this is above Fyn's z-index to appear on top */
  font-size: calc(10px + 3vmin);
  font-family: 'gaegu';
  margin-left: 10px;
  max-width: 30vw;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  top: 100%; /* It's now at the bottom of the bubble */
  left: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #00aabb;
  border-bottom: 0;
  border-right: 0;
  margin-left: -10px;
  margin-top: -1px;
}


.stopwatch {
  font-size: 24px;
  font-weight: bold;
  border: 2px solid black;
  padding: 10px;
  display: inline-block;
  margin-left: 20px;
}

.stopwatch .minutes,
.stopwatch .seconds {
  padding: 5px;
  border-radius: 5px;
  background-color: #f7f7f7;
  margin: 0 5px;
}

.stopwatch .minutes {
  color: blue;
}

.stopwatch .seconds {
  color: red;
}


/* Hide writing lines when not printing */
.writing-lines {
  display: none;
}

.story-textarea {
  /* Your styles for the textarea here */
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: calc(10px + 1.5vmin)
}

.feedback-text {
  /* Your styles for the feedback text here */
  font-size: calc(10px + 0.75vmin);
  color: #333;
}

.feedback-display {
  /* Your styles for the feedback text here */
  font-size: calc(10px + 3vmin);
  width: 90%;
}


/* Show writing lines when printing */
@media print {
  .writing-lines {
    display: block;
  }
  svg {
    display: none !important;
  }
  .questionbox {
    display: none !important;
  }
  .pagebox {
    display: none !important;
  }
  .levelbox {
    display: none !important;
  }
  .pointsbox {
    display: none !important;
  }
  .footer {
    display: none !important;
  }

  .nav-links {
    display: none;
  }

  .Nav {
    display: none;
  }
  .story button {
    display: none;
  }
}

/* Style for writing lines */
.writing-lines ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.writing-lines li {
  height: 1cm;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.lined-paper .writing-lines {
  display: none;
}

.button-container {
  position: relative; /* Ensure relative positioning for the container */
  z-index: 7; /* Ensure the button is positioned above the SVG */
  max-width: 66vw; /* Set a maximum width value */
  margin: 20px; /* Add margin around the container */
  margin-left: 13%;
  overflow: auto;
  margin-top: 100px;
  padding-top: 180px;
  padding-bottom: 100px;
}

.button-container button {
  position: relative; /* Ensure relative positioning for the button */
  /* Add any other styles for the button */
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  opacity: 0.8;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(10px + 3vmin);
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 12px;
  font-family: 'gaegu';
}

button .back-button {
  background-color: red;
  border: none;
  color: white;
  opacity: 0.8;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(10px + 3vmin);
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 12px;
  font-family: 'gaegu';
  position: relative;
}

.fyns-button {
  position: relative; /* Ensure relative positioning for the button */
  /* Add any other styles for the button */
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  opacity: 0.8;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(10px + 3vmin);
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 12px;
  font-family: 'gaegu';
}

.column-question {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.question {
  font-size: calc(10px + 4vmin);

}

.questionMaths {
  z-index: 5;
  font-size: calc(10px + 6vmin);
  background-color: #1aa21e29;
  border-radius: 12px;
  text-shadow: 2px 2px 2px #111;
}

.question button {
  background-color: #4CAF50; /* Green */
  font-family: 'gaegu';
  border: none;
  color: white;
  opacity: 0.8;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: calc(10px + 3vmin);
  margin: 4px 4px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 12px;
}

.question button:hover {
  opacity: 1;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

button:hover {
  opacity: 1;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}

.pagebox {
  z-index: 10;
  width: 40%;
  position: fixed;
  top: 3vh;
  left: 50%; /* Position the left edge of the element halfway across the parent */
  transform: translateX(-50%); /* Shift the element back left by half its own width */
  filter: drop-shadow(4px 4px 4px #4a4949);
  display: grid; /* Changes here */
  display: flex; /* Updated to flex */
  justify-content: center; /* Added */
  align-items: center; /* Added */
  white-space: nowrap;
  /* overflow: hidden; */
}

.pagebox svg { /* New rule targeting the SVG */
  position: absolute;
  width: 100%;
  height: 100%;
}

p.page {
  z-index: 2;
  /* position: absolute; removed */
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: 'gaegu';
  font-size: calc(10px + 6vh);
  text-shadow: 2px 2px 2px #111;
}

.pointsbox {
  z-index: 10;
  width: 20%;
  position: fixed;
  top: 9vh;
  right: 5vw;
  filter: drop-shadow(4px 4px 4px #4a4949);
  display: grid; /* New rule */
  place-items: center; /* New rule */
}

.pointsbox svg { /* New rule targeting the SVG */
  position: absolute;
  width: 100%;
  height: 100%;
}

p.points {
  z-index: 2;
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: 'gaegu';
  font-size: calc(10px + 4vmin);
  text-shadow: 2px 2px 2px #111;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 25%; /* New rule */
}

.Game {
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 10vh;
}

#phaser-game {
  width: 100%;
  height: 100%;

}


.levelbox {
  z-index: 10;
  width: 20%;
  position: fixed;
  top: 9vh;
  left: 5vw;
  filter: drop-shadow(4px 4px 4px #4a4949);
  display: grid; /* Changes here */
  place-items: center; /* This will center all child elements */
}

p.level {
  z-index: 2;
  position: absolute; /* Changes here */
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: 'gaegu';
  font-size: calc(10px + 4vmin);
  text-shadow: 2px 2px 2px #111;
  white-space: nowrap;
  overflow: hidden;
}

.questionbox {
  z-index: 10;
  width: 20%;
  position: fixed;
  top: 18vh;
  left: 5vw;
  filter: drop-shadow(4px 4px 4px #4a4949);
  display: flex; /* Changed from grid to flex */
  justify-content: center;
  align-items: center;
}

.questionbox svg { /* New rule targeting the SVG */
  position: absolute;
  width: 100%;
  height: 100%;
}

p.questionText {
  z-index: 2;
  /* position: absolute; removed */
  background-color: transparent;
  color: rgb(255, 255, 255);
  font-family: 'gaegu';
  font-size: calc(10px + 3vmin);
  text-shadow: 2px 2px 2px #111;
  white-space: nowrap;
}




.footer {
  position: fixed;
  width: 100%;
  background-color: #333;
  height: 5vh;
  bottom: 0;
  z-index: 20;
  position: relative;
}

.App-mid.footer {
  position: fixed; /* Fixed positioning */
  bottom: 0; /* Stick to the bottom */
  left: 0; /* Stick to the left */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center; /* Center the main content */
  align-items: center;
}

.footer-svg-container {
  position: absolute; /* Absolute positioning */
  right: 0; /* Stick to the right */
  display: flex;
  justify-content: flex-end;
  gap: 1%;
  justify-content: space-around;
}

.footer-svg {
  display: flex;
  max-width: 50px;
  width: 30%;  /* Reduce width by 70% */
  height: auto; /* Keep the aspect ratio */
  justify-content: flex-end;
}

.terms-button {
  background-color: #444; /* Dark gray background */
  color: #fff; /* White text for better contrast */
  border: none; /* Removing the border for a more modern look */
  padding: 8px 16px; /* Adjusted padding for a smaller button */
  cursor: pointer; /* Hand cursor on hover */
  font-size: 14px; /* Font size */
  border-radius: 20px; /* Rounded corners */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transition effect for background color and a subtle grow effect */
}

.terms-button:hover {
  background-color: #555; /* Slightly darker gray on hover */
  transform: scale(1.05); /* Slightly enlarges the button on hover for a subtle effect */
}

.games-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust the minmax values based on your content */
  gap: 20px; /* Space between grid items */
  padding: 20px;
}

.game-item {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.mainText {
  font-size: calc(10px + 4vmin);
}

.comingsoon {
  font-family: 'topsecret';
  font-size: calc(10px + 5vmin);
  margin-bottom: 80px;

}

.comingsoon h1{
  color: red;
  transform: rotate(-5deg);
}

.comingsoon li{
  font-family: 'gaegu';
  font-size: calc(10px + 3vmin);
}
/* Styles for landscape mode */
@media screen and (orientation: portrait) {
  .pagebox {
    top: 4vh;
    font-size: calc(10px + 2vmin);
  }
  .questionbox {
    top: 20vh;
    font-size: xx-small;
  }
  .pointsbox {
    top: 10vh;
    font-size: x-small;
  }
  .levelbox {
    top: 15vh;
    font-size: x-small;
  }

  .svg-fyn {
    bottom: 17vh;
    left: 0vw;
    height: 33vw;
    z-index: 10;
  }

  .svg-background {
    bottom: 5vh;
  }
  .sun {
    left: 5vh;
  }
  p.page {
    font-size: calc(3px + 5vh);
  }

  .button-container {
    margin-top: 200px;
  }

  .arrow-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 50;
    right: 1vw;
    top: 17vh;
    font-size: calc(10px + 1.2vmin);
  }

  .sea-background {
    bottom: 0;
  }
}

@keyframes eyeballAnimation {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-5px);
  }
}

#leyeball, #reyeball  {
  animation: eyeballAnimation 7s ease infinite;
}



@keyframes dance {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

.dancing {
  animation: dance 1s ease infinite;
}

#bushes {
  transform: translate(100px,-150px);
}

.number-line-container {
  max-width: 90%;
  padding: 10px;
  border-radius: 5px;
  margin: 10px auto;
  position: relative;
}

.number-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.horizontal-line {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: black;
  top: 50%;
  z-index: 1;
}

.vertical-line {
  height: 10px;
  width: 1px;
  background-color: black;
  position: relative;
  z-index: 2;
}

.vertical-line.arrow {
  height: 20px;
}

.arrow-mark {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: red;
  left: -5px;
  top: 20px;
  border-radius: 50%;
}

.number-label {
  position: absolute;
  z-index: 3;
  /* Resetting common properties that might affect styling */
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  font-size: 100%; /* Reset font size to default */
  font-weight: normal; /* Reset font weight to normal */
  line-height: normal; /* Reset line height to normal */
  text-align: left; /* Reset text alignment to left */
  color: inherit; /* Inherit the color from the parent */
  /* Add any other properties that you want to reset */
  bottom: 25px;
}

.number-label.start {
  left: -10px;
}

.number-label.end {
  right: -20px;
}

.number-line-wrapper {
  position: relative; /* This makes the labels position relative to this container */
}


.terms-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lato', sans-serif;
  z-index: 1000;
}

.terms-content {
  background: #ffffff;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  max-height: 90%;
  overflow: auto;
  position: relative;
}

.close-terms {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.footer button {
  font-family: 'Lato', sans-serif;
  /* Other styles for the button */
}

.terms-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.terms-content h3 {
  font-size: 20px;
  margin: 15px 0;
}

.terms-content h4 {
  font-size: 18px;
  margin: 12px 0;
}

.terms-content p {
  line-height: 1.5;
  margin: 10px 0;
}

.play-pause-button, .mute-button {
  background-color: transparent;
  border: none;
}
.play-pause-button:hover, .mute-button:hover {
  background-color: #111;
}
.play-pause-button #play {
  display: none;
}

.play-pause-button #pause {
  display: block;
}

.play-pause-button.playing #play {
  display: block;
}

.play-pause-button.playing #pause {
  display: none;
}

.mute-button.toMute #toMute {
  display: block;
}

.mute-button.toMute #unMute {
  display: none;
}

.mute-button.unMute #toMute {
  display: none;
}

.mute-button.unMute #unMute {
  display: block;
}

.overlay-popup {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0,0,0,0.7);
  color: white;
  padding: 10px;
  z-index: 1; /* make sure it's above other elements */
}

.arrow-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 50;
  right: 8vw;
  top: 20vh;
  font-size: calc(10px + 1.2vmin);
}

.animated-text {
  font-family: 'gaegu';
  color: white;
  text-shadow: 2px 2px 2px #111;


}

.animated-arrow,
.animated-arrow,
.animated-text {
  animation: bounceFade 10s infinite;
  z-index: 20;
}

@keyframes bounceFade {
  0%, 10%, 90%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
  5%, 95% {
    transform: translateY(-10px);
    opacity: 1;
  }
  20%, 80% {
    opacity: 0;
  }
}




.book-info {

  height: 300px; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add a border for visual separation */
  padding: 10px; /* Optional: Add some padding */
  margin-bottom: 20px; /* Optional: Add some margin to separate it from other elements */
  z-index: 20;
}

.comp-questions {
  height: 300px; /* Set a fixed height */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Optional: Add a border for visual separation */
  padding: 10px; /* Optional: Add some padding */
  margin-bottom: 20px; /* Optional: Add some margin to separate it from other elements */
  z-index: 20;
}