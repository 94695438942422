.navbar {
    display: flex;
    justify-content: space-between;
    z-index: 20;
}

.topLogo {
    width: 8rem;
    height: 6rem;
    flex-shrink: 0;
    display: flex;
    margin-left: 3rem;
    margin-top: 3rem;
    margin-right: 4rem;
}

.topLogo img {
    flex-shrink: 0;
}

.topLogo h1 {
    margin: 0;
    color: #000;
    text-align: center;
    font-size: calc(10px + 3vmin);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.075rem;
}

.topLogo h2 {
    margin: 0;
    color: #000;
    font-size: calc(10px + 2vmin);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.06rem;
}

.main-links {
    height: 1.6875rem;
    display: flex;
    gap: 3rem;
    margin-top: 3rem;
    justify-content: flex-end;
    z-index: 20;
}

.main-links a {
    color: #000;
    font-size: calc(10px + 1.5vmin);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.0375rem;
    text-decoration: none;
}
.social-media-links {
    margin-top: 3rem;
    display: flex;
    gap: 1.5rem;
    justify-content: flex-end;
    margin-right: 3rem;
}

.social-media-links a {
    text-decoration: none;
    color: black;
}

.page1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: #000;
    position: relative;
    height: 65vh;
    justify-content: space-around;
  }

  .page1 .text-container {
    z-index: 2; /* Ensures text is above the image */
    flex-basis: 40%; /* Adjust this as needed to control width */
    margin-right: -2rem; /* Overlap adjustment */
}

  .page1 .levels-container {
    display: flex;
    flex-direction: column;

}
.page1 .levels-container ul {
    margin-left: 10vw; /* Removes the left margin */
    margin-right: 10vw; /* Removes the left margin */
    padding-left: 0; /* Removes padding that might also indent items */
    list-style-type: circle; /* Removes the default list item dot */
  }

  /* If you want to ensure that all li elements within levels-container have no dots */
  .page1 .levels-container li {
    list-style-type: circle;
    text-align: left; /* This line is technically only needed once, in the ul rule, but added here for completeness */
  }

.image-and-buttons {
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 40%;
}

.webimage {
    z-index: 1; /* Ensures image is below the text */
    flex-basis: 40%; /* Adjust this as needed */
    display: flex;
    justify-content: center; /* Centers the image in its container */

}

.webimage img{
    width: 35vw;
    filter: drop-shadow(4px 4px 10px rgba(39, 166, 32, 0.20));
    margin-bottom: 0;

}

.page1 h1{
    color: #000;
    font-size: calc(5px + 3vmin);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.12rem;
    z-index: 1;
    text-align: left;
    margin-left: 7rem;
}

.button-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    flex-basis: 20%;
    width: 100%;
    margin-top: 2px;
}

.page1 .button-container .button {
    background-color: #4CAF50; /* Green */
    font-family: 'gaegu';
    border: none;
    color: white;
    opacity: 0.8;
    padding: 10px 64px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(10px + 2vmin);
    margin: 0.5rem 4px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    border-radius: 12px;
    width: 40%;
    right: 20%;
    bottom: 5%;
}

.page2 {
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title {
    margin-left: -20rem;
    position: relative;
}

.title h1{
    color: #000;
    font-size: calc(10px + 7vmin);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18rem;
    text-align: left;

}

.title .green {
    height: calc(10px + 3vmin);
    border-radius: 2rem;
    background: linear-gradient(84deg, rgba(34, 165, 26, 0.00) 2.61%, rgba(34, 165, 26, 0.88) 85.19%);
    z-index: -1;
    bottom: 3px;
    width: 100%;
    position: absolute;
}

.threeColumns {
    display: flex;
    gap: 8%;
    margin-bottom: 2rem;
    max-width: 80%;
}

.column {
    position: relative;
    background: linear-gradient(348deg, rgba(255, 175, 8, 0.11) 5.1%, rgba(217, 217, 217, 0.00) 103.48%);
    box-shadow: 4px 4px 8px rgba(255, 6, 60, 0.23);
    border-radius: 1rem;  /* This will round the corners */
    min-width: 23%;
}

h2 {
    margin-left: 10vw;
    margin-right: 10vw;
}


.column h1 {
    color: #000;
    font-size: calc(10px + 2vmin);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0375rem;
    text-align: left;
}

.column p {
    color: #000;
    font-size: calc(10px + 1vmin);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.0675rem;
    text-align: left;
}

.features ul {
    list-style-type: none; /* Removes default bullets */
    margin-left: 10vw; /* Ensures there's no default margin pushing it to the right */
    margin-right: 10vw; /* Ensures there's no default margin pushing it to the right */
    padding-left: 0; /* Aligns content to the very left */
    text-align: left; /* Ensures text within is aligned left */
}



.page3 {
    background: #011837;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: relative;
}

.page3 h1 {
    position: relative;
    color: #FFF;
    text-align: center;
    font-size: calc(10px + 7vmin);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: 10%;
    z-index: 2;
}

.page3 p {
    width: 21.4375rem;
    color: #FFF;
    font-size: calc(10px + 1.5vmin);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1125rem;
    text-align: left;
    margin-right: 10%;
}

.twoColumns {
    position: relative;
    display: flex;
    gap: 6rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 7%;

}

.twoColumns img {
    width: 25%;
    margin-left: 10%;
}

.page4 {
    background: #011837;
    justify-content: center;
    align-items: center;
    width: 100vw;
    position: relative;

}

.page4 h1 {
    position: relative;
    color: #fff;
    text-align: center;
    font-size: calc(10px + 7vmin);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: 10%;
    margin-bottom: 12%;
    z-index: 2;
    text-align: left;
}

.page4 p {
    margin-left: 10%;
    color: #fff;
    font-size: calc(10px + 3vmin);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1125rem;
    text-align: left;
}

.page4 .button {
    background-color: #4CAF50; /* Green */
    font-family: 'gaegu';
    border: none;
    color: white;
    opacity: 0.8;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: calc(10px + 3vmin);
    margin: 4px 4px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    border-radius: 12px;
    width: 334px;
    height: 97;
    right: 5rem;
    top: 25rem;
}

.pageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    position: relative;
}

.pricingCard:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  .pricingCard:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .pricingCard.popular {
    background-color: #e6fff2;  /* Light green */
  }

.pricingSection {
    text-align: center;
    padding: 2em 0;
}

.pricingOptions {
    display: flex;
    justify-content: space-around;
}

.pricingCard {
    border: 1px solid #ccc;
    padding: 1em;
    width: 30%;
}


@media screen and (max-width: 768px) {

    .topLogo {
        height: 4rem;
        flex-shrink: 0;
        display: flex;
        margin-left: 2rem;
        margin-top: 2rem;
        margin-right: -4rem;
    }
    .main-links {
        height: 1.6875rem;
        display: flex;
        gap: 0.5rem;
        margin-top: 3rem;
    }

    .main-links a {
        font-size: calc(10px + 0.5vmin);
    }

    .social-media-links {
        margin-top: 3rem;
        justify-content: flex-end;
        margin-right: 3rem;
        margin-left: 2%;
        height: 1.6875rem;
        display: flex;
        gap: 1rem;
    }

    .page1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto; /* Adjust height for mobile view */
    }

    .page1 .text-container {
        z-index: 2;
        flex-basis: 100%; /* Full width */
        margin-right: 0; /* Reset margin */
        text-align: center; /* Center-align text for mobile */
        padding: 1rem; /* Add padding for better readability */
    }

    .page1 h1 {
        font-size: calc(3px + 5vmin); /* Increase font size for better visibility */
        margin: 0; /* Reset margin */
    }

    .webimage {
        z-index: 1;
        flex-basis: 100%;
        margin-bottom: 1rem; /* Add some space between image and buttons */
    }

    .webimage img {
        width: 80vw; /* Adjust width */
        margin: 0; /* Reset margin */
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        flex-basis: 100%; /* Full width */
        margin-top: 1rem; /* Space above the buttons */
    }

    .page1 .button-container .button {
        background-color: #4CAF50;
        font-family: 'gaegu';
        padding: 10px 32px; /* Adjust padding */
        font-size: calc(10px + 3vmin); /* Adjust font size */
        width: 70%; /* Adjust width */
        margin: 0.5rem auto; /* Center buttons */
        border-radius: 12px;
    }



.page2 {
    left: 0;
}
.page2 h1{
    margin-left: 0rem;
}

.page3 {
    margin-top: 3rem;
    width: 100vw;
}
.page3 p{
    margin-right: 0;
    width: 80vw;
}

.page3 h1 {
    position: relative;
    color: #FFF;
    text-align: center;  /* This centers the text within the h1 element */
    font-size: calc(10px + 4vmin);
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-left: 0;  /* Adjusted from 10% to 0 */
    margin-right: 0;  /* Added this line to ensure no margin on the right */
    z-index: 2;
}

.page3 .green {
    right: 15rem;
}

.twoColumns {
    flex-direction: column;
     gap: 2rem;
}

.twoColumns img {
    width: 25%;
    margin-left: 0%;
}

.threeColumns {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10rem;
}

.column {
    margin-bottom: 3rem;
    width: 35rem;
    max-width: 80%; /* Adjusted from 23% to 20% */
    box-sizing: border-box;
    padding: 10px;
}
.logoText {
    display: none;
}

.page4 {
    max-width: 30rem;
    margin-left: 10rem;
}
.page4 .button {
    top: 35rem;
}
.title {
    margin-left: 0;
}

.title h1{
    font-size: calc(10px + 4vmin);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.18rem;
    text-align: left;

}

.threeColumns {
    gap: 4%;
    margin-bottom: 0rem;
}
.column h1, .column p {
    margin: 0; /* Example: Remove margin */
    padding: 0; /* Example: Remove padding */
}

.column h1 {
    /* ...other styles */
    font-size: calc(10px + 1.5vmin); /* Adjusted from calc(10px + 2vmin) */
}

.column p {
    /* ...other styles */
    font-size: calc(10px + 0.5vmin); /* Adjusted from calc(10px + 1vmin) */
}
.page4 h1, .page4 p {
    margin-left: 0; /* Reset margin-left */
    text-align: center; /* Center text */
    /* ...other styles */
}
.page4 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
    /* ...other styles */
}

.page4 .button {
    right: auto; /* Reset right */
    top: auto; /* Reset top */
    margin: 1rem auto;
    width: 80%;
}

.pricingOptions {
    flex-direction: column;
    align-items: center;
  }

  .pricingCard {
    width: 90%;
    margin: 1em auto;
  }

  .pricingCard:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .pricingCard:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

}



@media screen and (max-width: 1300px) {
    .logoText {
        display: none;
    }
}